import { graphql } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import PortableText from 'react-portable-text';

const SectionStyles = styled.section`
  max-width: 850px;
  margin: 0 auto;
  padding: var(--padding-large) var(--padding-medium);

  & > * {
    margin-bottom: var(--padding-large);
  }

  .title-container {
    display: flex;
    align-items: baseline;

    h2 {
      font-weight: 600;
      margin-right: var(--padding-small);
    }
    p {
      font-size: 1.6rem;
      font-weight: 400;
    }
  }
`;

export default function BioPage({ data }) {
  const { bio } = data;

  return (
    <SectionStyles>
      <div className="title-container">
        <h2>Michele Sambin</h2>
        <p>Padova 1951</p>
      </div>
      {bio.pageBuilder.map((item) =>
        item._type === 'image' ? (
          <GatsbyImage
            image={item.asset.gatsbyImageData}
            alt="michele sambin"
          />
        ) : (
          <PortableText content={item._rawText} />
        )
      )}
    </SectionStyles>
  );
}

export const query = graphql`
  query {
    bio: sanityBio {
      pageBuilder {
        ... on SanityParagraph {
          _type
          _rawText
        }
        ... on SanityImage {
          _type
          asset {
            gatsbyImageData(width: 1700, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
